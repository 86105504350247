import React from "react";
import { MUIProviders } from "@akj-dev/shared-components";
import { Cpq } from "@akj-dev/cpq";
import makeStyles from "@mui/styles/makeStyles";
import { getURLParams } from "./helpers/getURLParams";
import axios from "axios";
import { WizardData } from "./react-app-env";

// CPQ parameters in wizardData can be overridden by URL params for testing
const urlParams = getURLParams();

/**
 * Affinity Sales App for launch from Daisy Central
 */
export function AppStandalone() {
  return (
    <MUIProviders>
      <App />
    </MUIProviders>
  );
}

function App() {
  const classes = useStyles();
  return (
    <div className={classes.scroll}>
      <div className={classes.outer}>
        <Cpq
          hostEnv={getParam("host_env")}
          userResignsPermission={getParam("user_resigns_permission")}
          accountId={getParam("account_id")}
          accountName={getParam("account_name")}
          leadId={getParam("lead_id")}
          leadNumber={getParam("lead_number")}
          opportunityId={getParam("opportunity_id")}
          opportunityName={getParam("opportunity_name")}
          opportunityNumber={getParam("opportunity_number")}
          orderId={window.wizardData.order_id}
          // Note order_id on in URL params is different than order_id in wizardData according to DC
          savedOrderId={urlParams.order_id}
          fetchFn={dcAxios}
          baseUrl={getParam("dc_api_url_base")}
        />
      </div>
    </div>
  );
}

/**
 * Get CPQ init params
 * Note cypressWizardData if for Cypress tests only.
 * We can't use just URL params because of DC's double meaning for order_id
 */
const getParam = (key: keyof WizardData) =>
  window.cypressWizardData && window.cypressWizardData.hasOwnProperty(key)
    ? window.cypressWizardData[key]
    : urlParams[key] || window.wizardData[key];

/**
 * DC API Axios instance
 */
const dcAxios = axios.create();

dcAxios.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    config.baseURL = getParam("dc_api_url_base");
    return config;
  },
  (error) => Promise.reject(error)
);

const useStyles = makeStyles((theme) => ({
  outer: {
    maxWidth: 1600,
    margin: "0 auto",
    padding: theme.spacing(0, 2),
  },
  // Note: Something is adding overflow:hidden to body and I'm not sure what, hence this.
  scroll: {
    overflow: "scroll",
    width: "100vw",
    height: "100vh",
  },
}));
