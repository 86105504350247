/**
 * Get URL parameters as object.
 * Fails silently for malformed strings such as
 * LaunchWizardCreateOrder?account_id=QIK32606;opportunity_name=test
 * which is used by DC
 * @returns {{}}
 */
export function getURLParams() {
  const search = window.location.search.substring(1);
  let params;
  try {
    params = JSON.parse(
      (
        '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
      )
        .replace(/"false"/g, "false")
        .replace(/"true"/g, "true")
    );
  } catch (e) {
    params = {};
  }
  return params;
}
