import React from "react";
import ReactDOM from "react-dom";
import { AppStandalone } from "./AppStandalone";
import { initSentry } from "./initSentry";

console.log(`
Git Revision: ${process.env.REACT_APP_GIT_SHA}
Stage: ${process.env.REACT_APP_STAGE}
`);

initSentry();

ReactDOM.render(
  <React.StrictMode>
    <AppStandalone />
  </React.StrictMode>,
  document.getElementById("root")
);
