import * as Sentry from "@sentry/browser";

const sentryDSN = "https://61776071c01348fba93b056fade37478@sentry.io/288885";

/**
 * Initialise Sentry monitoring
 * TODO: Separate DSN for Suite and Sales?
 * @returns {{exception}|*}
 */
export function initSentry(): void {
  if (process.env.REACT_APP_STAGE !== "development") {
    Sentry.init({
      dsn: sentryDSN,
      environment: process.env.REACT_APP_STAGE!,
      release: process.env.REACT_APP_GIT_SHA!.trim(),
      beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            user: {
              name: window.wizardData.crm_user_name,
            },
          });
        }
        return event;
      },
      ignoreErrors: [
        "No matching address found",
        "You must provide a CLI",
        /DC Request to.* failed/,
      ],
    });

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: window.wizardData.crm_user_id,
        username: window.wizardData.crm_user_name,
        DC_account_id: window.wizardData.account_id,
        DC_order_id: window.wizardData.order_id,
      });
    });
  }
}
